import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import FileEntry from './FileEntry';
import files from '../../fixtures/files';
import { S3_DECRYPTED_LINK, S3_ENCRYPTED_LINK } from '../../constants';


export const CARD_STYLE = {
    fontFamily: 'monospace',
    color: '#CEFFFA',
    fontWeight: 'bolder',
    backgroundColor: '#0E2A33',
} as const;

const HEADER_STYLE = {
    fontSize: '1.2em',
    backgroundColor: '#273033',
    color: '#E3FFFC',
} as const;


interface Props {
    isGuiLoaded: boolean;
    isHackFinished: boolean;
}


const Docs: React.FC<Props> = (props: Props) => {
    const { isGuiLoaded, isHackFinished } = props;
    if (!isGuiLoaded) {
        return <></>;
    }
    return (
        <Accordion className="animated fadeIn">
            <Card style={ CARD_STYLE }>
                <Card.Header className="w-100" style={ HEADER_STYLE }>
                    FILE SYSTEM
                    <a className="float-right" href={ isHackFinished ? S3_DECRYPTED_LINK : S3_ENCRYPTED_LINK } download>
                        Download All
                    </a>
                </Card.Header>
            </Card>
            { files.map(({ name, encrypted, decrypted }, idx) => {
                return (
                    <>
                        { isHackFinished || (
                            <FileEntry
                                title={ `${name}` }
                                body={ encrypted.web }
                                eventKey={ `encrypted=${idx}` }
                                key={ `${name}` }
                                encrypted
                                isHackFinished={ isHackFinished }
                            />
                        )}
                        { isHackFinished && (
                            <FileEntry
                                title={ name }
                                body={ decrypted.web }
                                eventKey={ `decrypted-${idx}` }
                                key={ name }
                                encrypted={ false }
                                isHackFinished={ isHackFinished }
                                dangerouslySetInnerHtml={ decrypted.dangerouslySetInnerHtml }
                            />
                        )}
                    </>
                );
            }) }
        </Accordion>
    );
};

export default Docs;
