import React from 'react';
import { ReactThemes, ReactTerminalStateless } from 'react-terminal-component';
import * as JSTerminal from 'javascript-terminal';
import { GlobalState, SetState } from '../../App';

import './Terminal.scss';
import files from '../../fixtures/files';
import terminalService from '../../services/terminal-service';
import crackerJs from '../../fixtures/cracker.js';

const DBBEG = '-----BEGIN PGP MESSAGE-----';
const DBEND = '-----END PGP MESSAGE-----';
// eslint-disable-next-line max-len
const DBDEC = 'hQEMA2iGIGXpfVLsAQf+M833qlZsNJUq2WDrY2cgtJPtJWK+i9q3AfanVRS/tSfaQiyo607V98bAh2U30+MnTDFHiotNy3CevrS74Ogn5cLPpqRoZcJCGTbbdFk5Bnl8LbnKic+IEsXAQwfnym94lcMVmRoLw+wo02P4MjTgmDcj7awm+2qnS1aVQ+sBobYCQ+pl/oFQvO9g+VSAh4B+aqK/zmhIMI5sk32JgLjXH1l53GSPjRggKljEYeJFPhllPrJVjxwQ3eQre3vaq7nbvnDovQ8yEZMyyp3jqQJwVKv1pamfY6AShMfFA9c8b4lnhmnj7HXm2vylrL0uA8vvenuVVONyKogkO8D59GRoE9LBIwH25LPx6lN0hln9Mep43RJ1ZO8ZeF0YT5Sr4ab7bVHcGOa3jGIasND915v7Hje0yjxMnYA/fswaSuYfLWmNi4XKb48jw6O5UWks4wpTIEfFMPVVPceT8goo5+6vymo0wpzk6f2FXNL29Suafy4A3t1/lbQECry6UoODt7saJTJccL/QQWkyAnZ81ZUQkmXWE2qvOoSPuf+ke4boSg31JPe8BtWsIwy/ApCHqw8kRI24fPNfTjlWvywtVeLrLBnQyLGAzOmH+fCxzGnrtJck6JCoyW69FzhJ0GBP3O3RN8SWZ3zyFNr0FlWbs/3YRf8x187CzJ1JGgOGN41RPkRG9xMDblbd3F0tzTuoh+zfdXwgW6EPXt5GSYfv634gUdGhc7gypwjmleRiaiyUfanKGg5OLYsWhZWuDcnNHx2CtLCW6xSPYxb6yfs1QJCT3HgKVaDlHeGzBLnt96M7NYxzBOYQMEdB9Ob8dDkckBYIuN/WZzRrt3eK2MCh12qZBm8uV31X8JpI1GHC816olPt6iEACbiiSqigmigL4mTv8t07uaokiDKlD8GN4hph2BA5kmJ4h0Tu6ZS1PY3uFEUt/HAnmHSyFU6B0KtoEmf49hH+buxRt8IZ31UwIjFWNnp2OeHtSmg===0BMo';
// eslint-disable-next-line max-len
const DBENC = 'hQEMA2iGIGXpfVLsAQf%2BM833qlZsNJUq2WDrY2cgtJPtJWK%2Bi9q3AfanVRS%2FtSfaQiyo607V98bAh2U30%2BMnTDFHiotNy3CevrS74Ogn5cLPpqRoZcJCGTbbdFk5Bnl8LbnKic%2BIEsXAQwfnym94lcMVmRoLw%2Bwo02P4MjTgmDcj7awm%2B2qnS1aVQ%2BsBobYCQ%2Bpl%2FoFQvO9g%2BVSAh4B%2BaqK%2FzmhIMI5sk32JgLjXH1l53GSPjRggKljEYeJFPhllPrJVjxwQ3eQre3vaq7nbvnDovQ8yEZMyyp3jqQJwVKv1pamfY6AShMfFA9c8b4lnhmnj7HXm2vylrL0uA8vvenuVVONyKogkO8D59GRoE9LBIwH25LPx6lN0hln9Mep43RJ1ZO8ZeF0YT5Sr4ab7bVHcGOa3jGIasND915v7Hje0yjxMnYA%2FfswaSuYfLWmNi4XKb48jw6O5UWks4wpTIEfFMPVVPceT8goo5%2B6vymo0wpzk6f2FXNL29Suafy4A3t1%2FlbQECry6UoODt7saJTJccL%2FQQWkyAnZ81ZUQkmXWE2qvOoSPuf%2Bke4boSg31JPe8BtWsIwy%2FApCHqw8kRI24fPNfTjlWvywtVeLrLBnQyLGAzOmH%2BfCxzGnrtJck6JCoyW69FzhJ0GBP3O3RN8SWZ3zyFNr0FlWbs%2F3YRf8x187CzJ1JGgOGN41RPkRG9xMDblbd3F0tzTuoh%2BzfdXwgW6EPXt5GSYfv634gUdGhc7gypwjmleRiaiyUfanKGg5OLYsWhZWuDcnNHx2CtLCW6xSPYxb6yfs1QJCT3HgKVaDlHeGzBLnt96M7NYxzBOYQMEdB9Ob8dDkckBYIuN%2FWZzRrt3eK2MCh12qZBm8uV31X8JpI1GHC816olPt6iEACbiiSqigmigL4mTv8t07uaokiDKlD8GN4hph2BA5kmJ4h0Tu6ZS1PY3uFEUt%2FHAnmHSyFU6B0KtoEmf49hH%2BbuxRt8IZ31UwIjFWNnp2OeHtSmg%3D%3D%3D0BMo';
const COW_WIDTH = 28;
const SPONSOR_TEXT = 'Hack Linux sandbox is sponsored by tormentorsrus.com';


interface Files {
    [filename: string]: { content?: string; canModify?: boolean };
}


interface Props {
    isBootFinished: GlobalState['isBootFinished'];
    isGuiLoaded: GlobalState['isGuiLoaded'];
    globalState: GlobalState;
    setGlobalState: SetState<GlobalState>;
}


const Terminal: React.FC<Props> = (props: Props) => {
    const { isBootFinished, isGuiLoaded, globalState, setGlobalState } = props;

    const FILES: Files = {
        '/home': {},
        '/bin': {},
        '/bin/cracker.js': { content: crackerJs, canModify: false },
    };

    files.forEach(({ name, encrypted, decrypted }) => {
        FILES[`/home/${name}`] = { content: encrypted.term, canModify: false };
        if (globalState.isHackFinished) {
            const decryptedName = name.replace('.gpg', '');
            FILES[`/home/${decryptedName}`] = { content: decrypted.term, canModify: false };
        }
    });
    const TERMINAL_FILE_SYSTEM = JSTerminal.FileSystem.create(FILES);


    function chunkString(str: string, len: number): string {
        const size = Math.ceil(str.length / len);
        const r = Array(size);
        let offset = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < size; i++) {
            r[i] = str.substr(offset, len);
            offset += len;
        }
        return r.join('\n');
    }

    function cowsay(s: string, width = COW_WIDTH): string {
        if (s.length < COW_WIDTH) {
            width = s.length;
        }
        if (width < 4) {
            width = 4;
        }
        return `\
     ${'_'.repeat(width - 2)}
    /${' '.repeat(width - 2)}\\
    ${chunkString(s, width)}
    \\${'_'.repeat(width - 2)}/
    ${' '.repeat(width)}\\   ^__^
    ${' '.repeat(width)} \\  (oo)\\_______
    ${' '.repeat(width)}    (__)\\       )\\/\\
    ${' '.repeat(width)}        ||----w |
    ${' '.repeat(width)}        ||     ||
    `;
    }

    const HELP_TEXT = `
    help -- Show this message
    help []

    echo -- Print input string to output
    echo [input]

    ls -- List directory contents
    ls [dir]

    cd - Change the current directory
    cd [dir]

    cat -- Print file contents
    cat [file]

    cowsay -- Same as "echo" command, but with a cow
    cowsay [input]

    cowhelp -- Same as "help" command, but with a cow
    cowhelp []
    `;


    const TERMINAL_CMDS = JSTerminal.CommandMapping.create({
        ...JSTerminal.defaultCommandMapping,
        'print': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'function': (_: any, opts: Array<string>): any => {
                const input = opts.join(' ');
                return {
                    output: JSTerminal.OutputFactory.makeTextOutput(input),
                };
            },
            'optDef': {},
        },
        'cowsay': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'function': (_: any, opts: Array<string>): any => {
                let input = opts.join(' ');
                if (!input) {
                    input = cowsay(' How moo I assist ewe?');
                }
                else if (input.toLowerCase().includes('doxbox')) {
                    input = cowsay(DBDEC, COW_WIDTH);
                    input = `${input}\n\n${SPONSOR_TEXT}`;
                }
                else {
                    input = cowsay(input, COW_WIDTH);
                }
                return {
                    output: JSTerminal.OutputFactory.makeTextOutput(input),
                };
            },
            'optDef': {},
        },
        'help': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'function': (_: any): any => {
                return {
                    output: JSTerminal.OutputFactory.makeTextOutput(HELP_TEXT),
                };
            },
            'optDef': {},
        },
        'cowhelp': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'function': (_: any): any => {
                let output = HELP_TEXT;
                output += `
    cowsay doxbox -- Print system doxbox code if exists
    cowsay doxbox []
    `;
                const replaceStr = '__replaceMe__';
                const cowTemplate = cowsay(replaceStr, COW_WIDTH);
                output = cowTemplate.replace(replaceStr, output);
                return {
                    output: JSTerminal.OutputFactory.makeTextOutput(output),
                };
            },
            'optDef': {},
        },
    });


    const TERMINAL_HISTORY = JSTerminal.History.create(['a', 'b']);


    const TERMINAL_ENVS = JSTerminal.EnvironmentVariables.create();


    const TERMINAL_INITIAL_STATE = JSTerminal.EmulatorState.create({
        fs: TERMINAL_FILE_SYSTEM,
        commandMapping: TERMINAL_CMDS,
        environmentVariables: TERMINAL_ENVS,
        history: TERMINAL_HISTORY,
    });

    const [emulatorState, setEmulatorState] = React.useState(TERMINAL_INITIAL_STATE);
    const [bootSequenceIdx, setBootSequenceIdx] = React.useState(0);
    const [welcomeSequenceIdx, setWelcomeSequenceIdx] = React.useState(0);
    const [emulatorInput, setEmulatorInput] = React.useState('');

    function onEmulatorInputChange(input: string): void {
        if (isGuiLoaded) {
            setEmulatorInput(input);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onEmulatorStateChange(newState: any): void {
        setEmulatorInput(''); // clear stdin on enter
        setEmulatorState(newState);
    }

    // Boot and welcome sequence
    React.useEffect(() => {
        function printEmulatorMsg(msg: string, replace: boolean | number = false): void {
            const oldOutputs = emulatorState.getOutputs();
            const newOutput = JSTerminal.OutputFactory.makeTextOutput(msg);
            let newOutputs = null;

            if (replace && oldOutputs.size) {
                const replaceIdx = typeof replace === 'boolean' ? oldOutputs.size - 1 : replace;
                newOutputs = oldOutputs.set(replaceIdx, newOutput);
            }
            else {
                newOutputs = JSTerminal.Outputs.addRecord(oldOutputs, newOutput);
            }
            return newOutputs;
        }

        // Print boot sequence until finished, then update global state
        let timeoutId: NodeJS.Timeout;
        if (!isBootFinished) {
            if (bootSequenceIdx < terminalService.BOOT_SEQUENCE.length) {
                const delayedMessage = terminalService.BOOT_SEQUENCE[bootSequenceIdx];
                timeoutId = setTimeout(() => {
                    const stdout = printEmulatorMsg(delayedMessage.msg, delayedMessage.replace);
                    setEmulatorState(emulatorState.setOutputs(stdout));
                    setBootSequenceIdx(bootSequenceIdx + 1);
                }, delayedMessage.delayMs);
            }
            else {
                setGlobalState({ ...globalState, isBootFinished: true });
            }
        }

        // Print welcome sequence until finished, then update global state
        else if (!globalState.isGuiLoaded) {
            if (welcomeSequenceIdx < terminalService.WELCOME_SEQUENCE.length) {
                const delayedMessage = terminalService.WELCOME_SEQUENCE[welcomeSequenceIdx];
                timeoutId = setTimeout(() => {
                    const stdout = printEmulatorMsg(delayedMessage.msg, delayedMessage.replace);
                    setEmulatorState(emulatorState.setOutputs(stdout));
                    setWelcomeSequenceIdx(welcomeSequenceIdx + 1);
                }, delayedMessage.delayMs);
            }
            else {
                setGlobalState({ ...globalState, isGuiLoaded: true });
            }
        }
        return (): void => { clearTimeout(timeoutId); };
    }, [
        bootSequenceIdx,
        welcomeSequenceIdx,
        emulatorState,
        isBootFinished,
    ]);

    // Make any doxbox link linkable
    React.useEffect(() => {
        if (isBootFinished) {
            const history = Array.from(document.getElementsByClassName('sc-ifAKCX iNKVad'));
            const el = history.pop();
            const stdout = el?.innerHTML.toString();
            if (el && stdout && stdout.includes('hQEMA2iGIGXpfVLsAQf')) {
                const replaceStr = '__replaceMe__';
                const cowTemplate = cowsay(replaceStr, COW_WIDTH);
                const result = `${cowTemplate}\n\n${SPONSOR_TEXT}`;
                // eslint-disable-next-line max-len
                const href = `<a href="https://missions.teamzander.com/missions/doxbox?code=${DBENC}" rel="noopener noreferrer" target="_BLANK">${DBBEG}\n${chunkString(DBDEC, COW_WIDTH)}\n${DBEND}</a>`;
                el.innerHTML = result.replace(replaceStr, href);
            }
        }
    }, [emulatorState, isBootFinished]);

    return (
        <ReactTerminalStateless
            theme={ ReactThemes.sea }
            emulatorState={ emulatorState }
            promptSymbol={ isGuiLoaded ? '$' : '' }
            inputStr={ emulatorInput }
            onInputChange={ onEmulatorInputChange }
            onStateChange={ onEmulatorStateChange }
            acceptInput={ isGuiLoaded }
            clickToFocus
        />
    );
};

export default Terminal;
