import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment-timezone';
import noop from 'lodash/noop';

import Terminal from './components/terminal/Terminal';
import Navbar from './components/navbar/Navbar';
import Map from './components/map/Map';
import Chart from './components/chart/Chart';
import About from './components/about/About';
import Docs from './components/docs/Docs';
import { ETA } from './constants';


export interface GlobalState {
    isGuiLoaded: boolean;
    isBootFinished: boolean;
    isHackFinished: boolean;
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

const INITIAL_STATE: GlobalState = {
    isGuiLoaded: false,
    isBootFinished: false,
    isHackFinished: moment().isAfter(ETA),
};


const App: React.FC = () => {
    const [globalState, setGlobalState] = React.useState<GlobalState>(INITIAL_STATE);

    // Schedule isHackFinished for ETA
    React.useEffect(() => {
        if (globalState.isHackFinished) {
            return noop;
        }
        const timeoutId = setTimeout((): void => {
            setGlobalState({ ...globalState, isHackFinished: true });
        }, ETA.valueOf() - Date.now());
        return (): void => { clearTimeout(timeoutId); };
    });

    return (
        <>
            <Navbar isGuiLoaded={ globalState.isGuiLoaded } />
            <Container>

                {/* TERMINAL */}
                <Row>
                    <Col>
                        <Terminal
                            isBootFinished={ globalState.isBootFinished }
                            isGuiLoaded={ globalState.isGuiLoaded }
                            globalState={ globalState }
                            setGlobalState={ setGlobalState }
                        />
                    </Col>
                </Row>

                {/* PROGRESS CHART */}
                <Row className="mt-3 mb-5">
                    <Col>
                        <Chart { ...globalState } />
                    </Col>
                </Row>

                {/* CRACK COMPLETE BANNER */}
                { globalState.isGuiLoaded && globalState.isHackFinished && (
                    <Row className="mt-1 mb-2">
                        <Col>
                            <Alert variant="dark" className="w-100">
                                <span className="font-weight-bold">
                                    Drive was decrypted successfully.&nbsp;
                                </span>
                                Thank you for being complicit in our illegal cracking effort.
                            </Alert>
                        </Col>
                    </Row>
                )}

                {/* DOCUMENTS */}
                <Row>
                    <Col>
                        <Docs { ...globalState } />
                    </Col>
                </Row>

                {/* MAP */}
                <Row className="mt-5 mb-5">
                    <Col>
                        <Map isGuiLoaded={ globalState.isGuiLoaded } />
                    </Col>
                </Row>

                {/* ABOUT */}
                <Row className="mt-5 mb-5">
                    <Col>
                        <About isGuiLoaded={ globalState.isGuiLoaded } />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default App;
