export default `
#! /bin/node
const crypto = require('crypto');
const { exec } = require('child_process');


const CHARSET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@$%&*-?';


/**
 * Generate the command line string to execute in the shell.
 */
function getCmdStr(cipher, filepath, pw) {
    let outfile;
    if (filepath.endsWith('.aes')) {
        outfile = filepath.slice(0, filepath.length - 4);
    }
    else {
        outfile = \`\${filepath}.out\`;
    }
    return \`openssl \${cipher} -d -a -in \${filepath} -out \${outfile} -pass pass:\${pw}\`;
}


/**
 * Shell out and attempt decrypt file with password.
 */
function aes256Decrypt(filepath, pw) {
    const cmd = getCmdStr('aes-256-cbc', filepath, pw);
    return new Promise((resolve, reject) => {
        try {
            exec(cmd, (error) => {
                if (error) {
                    return reject(error);
                }
                return resolve(pw);
            });
        }
        catch (err) {
            reject(err);
        }
    });
}


/**
 * Encode/decode a string value between bases using CHARSET.
 */
function convertBase(value, fromBase, toBase) {
    const range = CHARSET.split('');
    const fromRange = range.slice(0, fromBase);
    const toRange = range.slice(0, toBase);

    // https://stackoverflow.com/a/32480941/3076390
    let decValue = value.split('').reverse().reduce((carry, digit, index) => {
        if (fromRange.indexOf(digit) === -1) {
            throw new Error(\`Invalid digit \${digit} for base \${fromBase}.\`);
        }
        const addum = fromRange.indexOf(digit) * (fromBase ** index);
        return carry + addum;
    }, 0);

    let newValue = '';
    while (decValue > 0) {
        newValue = toRange[decValue % toBase] + newValue;
        decValue = (decValue - (decValue % toBase)) / toBase;
    }
    return newValue || '0';
}


/**
 * Attempt all passwords of the given length.
 */
function aes256BruteForceLen(filepath, len = 1) {
    console.log('Attempting Brute Force for AES 256 Symmetric');
    let idx = CHARSET.length ** (len - 1);
    const endIdx = CHARSET.length ** len;
    return new Promise(async (resolve, reject) => {
        while (idx < endIdx) {
            const pw = convertBase(idx.toString(10), 10, CHARSET.length);
            try {
                const sha1 = crypto.createHash('sha1').update(pw).digest('hex');
                console.log(\`0x\${sha1}\`);
                await aes256Decrypt(filepath, pw);
                resolve(pw);
                break;
            }
            catch (err) {}
            idx += 1;
        }
        reject(new Error('Failed to crack password.'));
    });
}
`;
