/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import gpg_emails_01 from '!!raw-loader!./files_gpg/emails/emails_01.docx.gpg';
import gpg_emails_02 from '!!raw-loader!./files_gpg/emails/emails_02.docx.gpg';
import gpg_emails_03 from '!!raw-loader!./files_gpg/emails/emails_03.docx.gpg';
import gpg_emails_04 from '!!raw-loader!./files_gpg/emails/emails_04.docx.gpg';
import gpg_emails_05 from '!!raw-loader!./files_gpg/emails/emails_05.docx.gpg';
import gpg_emails_06 from '!!raw-loader!./files_gpg/emails/emails_06.docx.gpg';
import gpg_emails_07 from '!!raw-loader!./files_gpg/emails/emails_07.docx.gpg';
import gpg_emails_08 from '!!raw-loader!./files_gpg/emails/emails_08.docx.gpg';
import gpg_journal_01 from '!!raw-loader!./files_gpg/journal/journal_01.docx.gpg';
import gpg_journal_02 from '!!raw-loader!./files_gpg/journal/journal_02.docx.gpg';
import gpg_journal_03 from '!!raw-loader!./files_gpg/journal/journal_03.docx.gpg';
import gpg_journal_04 from '!!raw-loader!./files_gpg/journal/journal_04.docx.gpg';
import gpg_journal_05 from '!!raw-loader!./files_gpg/journal/journal_05.docx.gpg';
import gpg_journal_06 from '!!raw-loader!./files_gpg/journal/journal_06.docx.gpg';
import gpg_feel_good_script_mercy from '!!raw-loader!./files_gpg/streaming_vid_scripts/feel_good_script_mercy.docx.gpg';
import gpg_feel_good_script_sona from '!!raw-loader!./files_gpg/streaming_vid_scripts/feel_good_script_sona.docx.gpg';
import gpg_tt_recruitment_notes_email from '!!raw-loader!./files_gpg/emails/tt_recruitment_notes_email.docx.gpg';
import gpg_david_emails_aes from '!!raw-loader!./files_gpg/emails/david_emails_aes.docx.gpg';
import gpg_david_emails from '!!raw-loader!./files_gpg/emails/david_emails.docx.gpg';
import gpg_isaac_irc_log_01 from '!!raw-loader!./files_gpg/isaac_irc_log_01.txt.gpg';
import gpg_isaac_irc_log_02 from '!!raw-loader!./files_gpg/isaac_irc_log_02.txt.gpg';
import gpg_IMG_20200723_223830 from '!!raw-loader!./files_gpg/dating_profile_pics/IMG_20200723_223830.jpg.gpg';
import gpg_IMG_20200723_224849 from '!!raw-loader!./files_gpg/dating_profile_pics/IMG_20200723_224849.jpg.gpg';
import gpg_IMG_20200723_222720 from '!!raw-loader!./files_gpg/dating_profile_pics/IMG_20200723_222720.jpg.gpg';
import gpg_IMG_20200723_224929 from '!!raw-loader!./files_gpg/dating_profile_pics/IMG_20200723_224929.jpg.gpg';


/* eslint-disable max-len */
interface FileMeta {
    web: string;
    term: string;
    dangerouslySetInnerHtml?: boolean;
}

interface File {
    name: string;
    encrypted: FileMeta;
    decrypted: FileMeta;
}

interface Manifest {
    [filename: string]: string[];
}

const ERR_ENCRYPTED_TXT = '<cannot display encrypted file>';
const ERR_BINARY_TXT = '<cannot display binary file>';

const manifest: Manifest = {
    'emails/emails_01.docx.gpg': [gpg_emails_01, '/files_decrypted/emails/emails_01.docx'],
    'emails/emails_02.docx.gpg': [gpg_emails_02, '/files_decrypted/emails/emails_02.docx'],
    'emails/emails_03.docx.gpg': [gpg_emails_03, '/files_decrypted/emails/emails_03.docx'],
    'emails/emails_04.docx.gpg': [gpg_emails_04, '/files_decrypted/emails/emails_04.docx'],
    'emails/emails_05.docx.gpg': [gpg_emails_05, '/files_decrypted/emails/emails_05.docx'],
    'emails/emails_06.docx.gpg': [gpg_emails_06, '/files_decrypted/emails/emails_06.docx'],
    'emails/emails_07.docx.gpg': [gpg_emails_07, '/files_decrypted/emails/emails_07.docx'],
    'emails/emails_08.docx.gpg': [gpg_emails_08, '/files_decrypted/emails/emails_08.docx'],
    'journal/journal_01.docx.gpg': [gpg_journal_01, '/files_decrypted/journal/journal_01.docx'],
    'journal/journal_02.docx.gpg': [gpg_journal_02, '/files_decrypted/journal/journal_02.docx'],
    'journal/journal_03.docx.gpg': [gpg_journal_03, '/files_decrypted/journal/journal_03.docx'],
    'journal/journal_04.docx.gpg': [gpg_journal_04, '/files_decrypted/journal/journal_04.docx'],
    'journal/journal_05.docx.gpg': [gpg_journal_05, '/files_decrypted/journal/journal_05.docx'],
    'journal/journal_06.docx.gpg': [gpg_journal_06, '/files_decrypted/journal/journal_06.docx'],
    'streaming_vid_scripts/feel_good_script_mercy.docx.gpg': [gpg_feel_good_script_mercy, '/files_decrypted/streaming_vid_scripts/feel_good_script_mercy.docx'],
    'streaming_vid_scripts/feel_good_script_sona.docx.gpg': [gpg_feel_good_script_sona, '/files_decrypted/streaming_vid_scripts/feel_good_script_sona.docx'],
    'emails/tt_recruitment_notes_email.docx.gpg': [gpg_tt_recruitment_notes_email, '/files_decrypted/emails/tt_recruitment_notes_email.docx'],
    'emails/david_emails_aes.docx.gpg': [gpg_david_emails_aes, '/files_decrypted/emails/david_emails_aes.docx'],
    'emails/david_emails.docx.gpg': [gpg_david_emails, '/files_decrypted/emails/david_emails.docx'],
    'isaac_irc_log_01.txt.gpg': [gpg_isaac_irc_log_01, '/files_decrypted/isaac_irc_log_01.txt'],
    'isaac_irc_log_02.txt.gpg': [gpg_isaac_irc_log_02, '/files_decrypted/isaac_irc_log_02.txt'],
    'dating_profile_pics/IMG_20200723_223830.jpg.gpg': [gpg_IMG_20200723_223830, '/files_decrypted/dating_profile_pics/IMG_20200723_223830.jpg'],
    'dating_profile_pics/IMG_20200723_224849.jpg.gpg': [gpg_IMG_20200723_224849, '/files_decrypted/dating_profile_pics/IMG_20200723_224849.jpg'],
    'dating_profile_pics/IMG_20200723_222720.jpg.gpg': [gpg_IMG_20200723_222720, '/files_decrypted/dating_profile_pics/IMG_20200723_222720.jpg'],
    'dating_profile_pics/IMG_20200723_224929.jpg.gpg': [gpg_IMG_20200723_224929, '/files_decrypted/dating_profile_pics/IMG_20200723_224929.jpg'],
    'OBS-Studio-25.0.8-Full-Installer-x64 (2).exe': ['<failed to parse binary>', '<failed to open file>'],
    'FBI_Miami_S4_1_(WEB 720p).mkv': ['<failed to parse binary>', '<failed to open file>'],

};

export default Object.entries(manifest).map(([filename, contentArr]): File => {
    return {
        name: filename,
        encrypted: {
            web: filename.endsWith('.gpg') ? ERR_ENCRYPTED_TXT : ERR_BINARY_TXT,
            term: contentArr[0],
            dangerouslySetInnerHtml: false,
        },
        decrypted: {
            web: contentArr[1],
            term: contentArr[0],
            dangerouslySetInnerHtml: false,
        },
    };
});
