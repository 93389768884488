import React from 'react';
import { GlobalState } from '../../App';


const STYLE = {
    fontFamily: 'monospace',
    fontSize: '1.2em',
} as const;


interface Props {
    isGuiLoaded: GlobalState['isGuiLoaded'];
}

const About: React.FC<Props> = (props: Props) => {
    const { isGuiLoaded } = props;
    if (!isGuiLoaded) {
        return (
            <></>
        );
    }
    return (
        <div className="animated fadeIn text-light mt-3" style={ STYLE }>
            <p className="lead">THANK YOU FOR HACKING</p>
            <p>
                For as long as this site is open in your browser you are participating in an
                internationally-distributed cracking effort. Don't worry, we're the good guys.
            </p>
            <p className="lead">WTF?</p>
            <p>
                Zander Jones has been framed for a crime he didn't commit. We believe there is
                exonerating evidence in one of these files. The files belonged to a person
                named Isaac who was murdered by the same people who framed Zander. We're
                organizing to help him on&nbsp;
                <a href="https://missions.teamzander.com" target="__blank">missions.teamzander.com</a>.
            </p>
            <p className="lead">HOW?</p>
            <p>
                We're running a sandboxed VM in your browser. It understands simple unix
                commands. Go ahead and try it out.
            </p>
            <p className="lead">IS THIS ILLEGAL?</p>
            <p>
                Almost certainly. But they can't arrest all of us.
            </p>
            <p className="lead">IS THIS REAL?</p>
            <p>
                This is <a href="https://definitelyreal.com">definitely real</a>.
            </p>
        </div>
    );
};

export default About;
