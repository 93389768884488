/* eslint-disable react/jsx-indent */
import React from 'react';
import BSNavbar from 'react-bootstrap/Navbar';
import { GlobalState } from '../../App';


const NAV_STYLE = {
    minHeight: '4em',
} as const;

interface Props {
    isGuiLoaded: GlobalState['isGuiLoaded'];
}


const Navbar: React.FC<Props> = (props: Props) => {
    const { isGuiLoaded } = props;

    return (
        <BSNavbar className="mb-3" bg="dark" expand="lg" style={ NAV_STYLE }>
            <BSNavbar.Brand style={ { fontSize: '8px', lineHeight: '0.4rem' } }>
                {
                    isGuiLoaded && (
                        <pre className="animated fadeIn p-0 m-0">
██╗  ██╗ █████╗  ██████╗██╗  ██╗  ██╗     ██╗███╗   ██╗██╗   ██╗██╗  ██╗<br />
██║  ██║██╔══██╗██╔════╝██║ ██╔╝  ██║     ██║████╗  ██║██║   ██║╚██╗██╔╝<br />
███████║███████║██║     █████╔╝   ██║     ██║██╔██╗ ██║██║   ██║ ╚███╔╝ <br />
██╔══██║██╔══██║██║     ██╔═██╗   ██║     ██║██║╚██╗██║██║   ██║ ██╔██╗ <br />
██║  ██║██║  ██║╚██████╗██║  ██╗  ███████╗██║██║ ╚████║╚██████╔╝██╔╝ ██╗<br />
╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝  ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝<br />
                        </pre>
                    )
                }
            </BSNavbar.Brand>
        </BSNavbar>
    );
};

export default Navbar;
