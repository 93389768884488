import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';

import { GlobalState } from '../../App';
import ChartOverlay from './ChartOverlay';


const CHART_PRE_BOOT_VALUE = 0;
const CHART_DATA_MAX_LEN = 200;
const CHART_UPDATE_MS = 30;
const LINE_STYLE: React.SVGAttributes<React.ReactSVGElement>['style'] = {
    stroke: '#206176',
    strokeWidth: '0.5px',
    fill: 'none',
};

interface Props {
    isGuiLoaded: GlobalState['isGuiLoaded'];
    isBootFinished: GlobalState['isBootFinished'];
    isHackFinished: GlobalState['isHackFinished'];
}


const Chart: React.FC<Props> = (props: Props) => {
    const { isGuiLoaded, isBootFinished, isHackFinished } = props;
    const [chartData, setChartData] = React.useState<number[]>([CHART_PRE_BOOT_VALUE]);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            let newVal = CHART_PRE_BOOT_VALUE;
            if (isGuiLoaded) {
                const prevVal = chartData[chartData.length - 1];
                newVal = prevVal + (Math.random() - 0.5);
                if (newVal < 0) {
                    newVal = 0;
                }
            }
            const newChartData = [...chartData, newVal];
            if (newChartData.length > CHART_DATA_MAX_LEN) {
                newChartData.shift();
            }
            setChartData(newChartData);
        }, CHART_UPDATE_MS);
        return (): void => { clearInterval(intervalId); };
    });

    return (
        <div className="mt-3">
            <ChartOverlay { ...{ isGuiLoaded, isBootFinished, chartData, isHackFinished } } />
            {
                isGuiLoaded && (
                    <div className="animated fadeIn mh-100">
                        <Sparklines data={ chartData } limit={ CHART_DATA_MAX_LEN } style={ { maxHeight: '100px' } }>
                            <SparklinesLine color={ LINE_STYLE.stroke } style={ LINE_STYLE } />
                        </Sparklines>
                    </div>
                )
            }
        </div>
    );
};

export default Chart;
