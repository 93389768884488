import React from 'react';
import moment from 'moment';

import terminalService from '../../services/terminal-service';
import { ETA } from '../../constants';


const OVERLAY_STYLE: React.CSSProperties = {
    color: '#cefffa',
    position: 'absolute',
    fontFamily: 'monospace',
    fontSize: '1.1em',
    whiteSpace: 'pre-wrap',
    zIndex: 2,
};


interface Props {
    chartData: Array<number>;
    isBootFinished: boolean;
    isHackFinished: boolean;
    isGuiLoaded: boolean;
}


const ChartOverlay: React.FC<Props> = (props: Props) => {
    const { chartData, isBootFinished, isHackFinished, isGuiLoaded } = props;

    const now = moment();
    const etaDuration = moment.duration(ETA.diff(now));
    const etaLogScale = Math.floor(Math.log2(etaDuration.asMinutes()));

    let progressPct = 100 - etaLogScale * 4;
    progressPct = Number.isNaN(progressPct) ? 100 : progressPct;
    progressPct = progressPct < 5 ? 5 : progressPct;
    let timeEst;

    if (now > ETA) {
        timeEst = 'Any minute now...';
        progressPct = 100;
    }
    else {
        timeEst = etaDuration.humanize();
    }

    if (isHackFinished) {
        return (
            <p style={ OVERLAY_STYLE }>
                HPS: N/A
                <br />
                {
                    isBootFinished ? terminalService.fakeHash(40) : 'booting...'
                }
                <br />
                {
                    isGuiLoaded ? terminalService.fakeProgress(100, 40) : 'estimating progress...'
                }
                <br />
                {
                    isGuiLoaded ? 'ETA: Complete!' : 'estimating time remaining...'
                }
            </p>
        );
    }

    return (
        <p style={ OVERLAY_STYLE }>
            HPS: {
                Math.floor(chartData.reduce((sum, x) => sum + x))
            }
            <br />
            {
                isBootFinished ? terminalService.fakeHash(40) : 'booting...'
            }
            <br />
            {
                isGuiLoaded ? terminalService.fakeProgress(progressPct, 40) : 'estimating progress...'
            }
            <br />
            {
                isGuiLoaded ? `ETA: ${timeEst}` : 'estimating time remaining...'
            }
        </p>
    );
};

export default ChartOverlay;
