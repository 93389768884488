import simcityMessages from '../fixtures/simcity-messages';


type PrintMsgCb = (s: string, replace?: boolean | number) => void;


/**
 * Helper to return a promise that resolves to `s` after waiting `delayMs`.
 */
function delayMsg(s: string, delayMs: number): Promise<string> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(s);
        }, delayMs);
    });
}


/**
 * Helper class for displaying messages after a set delay.
 */
class DelayedMsg {
    readonly msg: string;
    readonly delayMs: number;
    readonly replace: boolean;

    constructor(msg: string, delay = 0, replace = false) {
        this.msg = msg;
        this.delayMs = delay;
        this.replace = replace;
    }

    async run(): Promise<string> {
        return delayMsg(this.msg, this.delayMs);
    }
}


function simCityStr(): string {
    return simcityMessages[Math.floor(Math.random() * simcityMessages.length)];
}


function fakeHash(len = 50): string {
    const hexChars = '0123456789abcdef';
    const randChar = (): string => hexChars[Math.floor(Math.random() * hexChars.length)];
    const stringBuilder = [];
    while (stringBuilder.length < len - 2) {
        stringBuilder.push(randChar());
    }
    return `0x${stringBuilder.join('')}`;
}


function fakeProgress(percentInt: number, len = 30): string {
    const numConstantChars = 3;
    const stepsComplete = Math.floor((percentInt / 100) * (len - numConstantChars));
    let padSize = len - (stepsComplete + numConstantChars);
    padSize = padSize > 0 ? padSize : 0;
    const progBar = '='.repeat(stepsComplete);
    const padding = ' '.repeat(padSize);
    return `[${progBar}>${padding}]`;
}


const BOOT_SEQUENCE: DelayedMsg[] = [
    new DelayedMsg('Loading...', 0),
    new DelayedMsg('Booting HackLinux Sandbox on virtual CPU 0x0', 1000, true),
    new DelayedMsg('HackLinux version 4.9.70-v7+ (dc4@dc4-XPS13-9333)', 100),
    new DelayedMsg('CPU: ARMv7 Processor [410fd034] revision 4 (ARMv7), cr=10c5383d', 0),
    new DelayedMsg('CPU: PIPT / VIPT nonaliasing data cache, VIPT aliasing instruction cache', 3, true),
    new DelayedMsg('HackLinuxACPICPU: ProcessorId=1 LocalApicId=0 Enabled', 300),
    new DelayedMsg('HackLinuxACPICPU: ProcessorId=2 LocalApicId=0 Enabled', 300, true),
    new DelayedMsg('HackLinuxACPICPU: ProcessorId=3 LocalApicId=0 Enabled', 300, true),
    new DelayedMsg('HackLinuxACPICPU: ProcessorId=4 LocalApicId=0 Enabled', 300, true),
    new DelayedMsg('Security policy: Safe sandbox policy (HackLinux Sandbox)', 0),

    new DelayedMsg(simCityStr(), 100),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg(simCityStr(), 100, true),
    new DelayedMsg('Boot complete.', 100),
    new DelayedMsg(' ', 0),

    new DelayedMsg('Fetching passwords to crack...', 0),
    new DelayedMsg(fakeProgress(0, 40), 5, true),
    new DelayedMsg(fakeProgress(3, 40), 5, true),
    new DelayedMsg(fakeProgress(6, 40), 5, true),
    new DelayedMsg(fakeProgress(9, 40), 5, true),
    new DelayedMsg(fakeProgress(12, 40), 5, true),
    new DelayedMsg(fakeProgress(15, 40), 5, true),
    new DelayedMsg(fakeProgress(18, 40), 5, true),
    new DelayedMsg(fakeProgress(21, 40), 5, true),
    new DelayedMsg(fakeProgress(24, 40), 5, true),
    new DelayedMsg(fakeProgress(27, 40), 5, true),
    new DelayedMsg(fakeProgress(30, 40), 5, true),
    new DelayedMsg(fakeProgress(33, 40), 5, true),
    new DelayedMsg(fakeProgress(36, 40), 5, true),
    new DelayedMsg(fakeProgress(39, 40), 5, true),
    new DelayedMsg(fakeProgress(42, 40), 5, true),
    new DelayedMsg(fakeProgress(45, 40), 5, true),
    new DelayedMsg(fakeProgress(48, 40), 5, true),
    new DelayedMsg(fakeProgress(51, 40), 5, true),
    new DelayedMsg(fakeProgress(54, 40), 5, true),
    new DelayedMsg(fakeProgress(57, 40), 5, true),
    new DelayedMsg(fakeProgress(60, 40), 5, true),
    new DelayedMsg(fakeProgress(63, 40), 5, true),
    new DelayedMsg(fakeProgress(66, 40), 20, true),
    new DelayedMsg(fakeProgress(69, 40), 20, true),
    new DelayedMsg(fakeProgress(72, 40), 20, true),
    new DelayedMsg(fakeProgress(75, 40), 20, true),
    new DelayedMsg(fakeProgress(78, 40), 20, true),
    new DelayedMsg(fakeProgress(81, 40), 30, true),
    new DelayedMsg(fakeProgress(84, 40), 30, true),
    new DelayedMsg(fakeProgress(87, 40), 30, true),
    new DelayedMsg(fakeProgress(90, 40), 30, true),
    new DelayedMsg(fakeProgress(93, 40), 30, true),
    new DelayedMsg(fakeProgress(96, 40), 40, true),
    new DelayedMsg(fakeProgress(99, 40), 40, true),
    new DelayedMsg(fakeProgress(100, 40), 40, true),
    new DelayedMsg('Success.', 100, true),
    new DelayedMsg(' ', 0),

    new DelayedMsg('$ /bin/cracker.js', 0),
    new DelayedMsg('Attempting Brute Force for AES 256 Symmetric', 10),
    new DelayedMsg(fakeHash(), 50),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),
    new DelayedMsg(fakeHash(), 50, true),

    new DelayedMsg(' ', 0),
    new DelayedMsg('^Z', 0),
    new DelayedMsg('[1]+  Stopped; sleep 9', 100),
    new DelayedMsg('$ bg', 100),
    new DelayedMsg('[1]+  sleep 9 &', 100),

    new DelayedMsg(' ', 0),
    new DelayedMsg('Bootstrapping GUI...', 0),
    new DelayedMsg('◐', 100),
    new DelayedMsg('◓', 100, true),
    new DelayedMsg('◑', 100, true),
    new DelayedMsg('◒', 100, true),
    new DelayedMsg('◐', 100, true),
    new DelayedMsg('◓', 100, true),
    new DelayedMsg('◑', 100, true),
    new DelayedMsg('◒', 100, true),
    new DelayedMsg('◐', 100, true),
    new DelayedMsg('◓', 100, true),
    new DelayedMsg('◑', 100, true),
    new DelayedMsg('◒', 100, true),
    new DelayedMsg('◐', 100, true),
    new DelayedMsg('◓', 100, true),
    new DelayedMsg('◑', 100, true),
    new DelayedMsg('◒', 100, true),
    new DelayedMsg('◐', 100, true),
    new DelayedMsg('◓', 100, true),
    new DelayedMsg('◑', 100, true),
    new DelayedMsg('◒', 100, true),
    new DelayedMsg('Success.', 100, true),
    new DelayedMsg(' ', 100),
];

const WELCOME_SEQUENCE = [
    new DelayedMsg('** Welcome to **', 1000),
    new DelayedMsg(`
██╗  ██╗ █████╗  ██████╗██╗  ██╗
██║  ██║██╔══██╗██╔════╝██║ ██╔╝
███████║███████║██║     █████╔╝
██╔══██║██╔══██║██║     ██╔═██╗
██║  ██║██║  ██║╚██████╗██║  ██╗
╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
██╗     ██╗███╗   ██╗██╗   ██╗██╗  ██╗
██║     ██║████╗  ██║██║   ██║╚██╗██╔╝
██║     ██║██╔██╗ ██║██║   ██║ ╚███╔╝
██║     ██║██║╚██╗██║██║   ██║ ██╔██╗
███████╗██║██║ ╚████║╚██████╔╝██╔╝ ██╗
╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝
    `, 800),

    new DelayedMsg(' ', 0),
    new DelayedMsg('Thank you for donating your GPU to our *ethical* botnet.', 0),
    new DelayedMsg('Your browser is now a node in our cracking network.', 0),
    new DelayedMsg(' ', 0),
    new DelayedMsg('Type "help" to list system commands', 100),
];


export default {
    BOOT_SEQUENCE,
    WELCOME_SEQUENCE,
    fakeHash,
    fakeProgress,
};
