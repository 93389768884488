import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { csv } from 'd3-fetch';
import { DSVRowArray } from 'd3-dsv';

import geography from '../../fixtures/world-map-topo.json';
import { GlobalState } from '../../App';


const MAP_RERENDER_INTERVAL_MS = 1000 * 60;

const colorScale = scaleLinear()
    .domain([0.2, 0.6])
    .range([0xff, 0x00]);


interface Props {
    isGuiLoaded: GlobalState['isGuiLoaded'];
}


function colorHexFromByteValue(byteVal: number): string {
    if (byteVal < 40) {
        return '#220000';
    }
    if (byteVal > 255) {
        return '#FF0000';
    }
    return `#${byteVal.toString(16).padStart(2, '0')}0000`;
}


const Map: React.FC<Props> = (props: Props) => {
    const { isGuiLoaded } = props;
    const [data, setData] = React.useState<DSVRowArray<string>>();
    const [forceRender, setForceRender] = React.useState(0);

    // Load CSV data on mount
    React.useEffect(() => {
        csv('/csv/vulnerability.csv').then((d) => {
            setData(d);
        });
    }, []);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setForceRender(forceRender + 1);
        }, MAP_RERENDER_INTERVAL_MS);
        return (): void => { clearTimeout(timeoutId); };
    }, [forceRender]);

    if (!isGuiLoaded) {
        return <></>;
    }

    return (
        <div className="bg-dark animated fadeIn" style={ { height: '100%' } }>
            <ComposableMap>
                <Geographies geography={ geography }>
                    {
                        ({ geographies }): React.ReactElement<typeof Geography>[] => {
                            return geographies.map((geo) => {
                                const d = data?.find((s) => s.ISO3 === geo.properties.ISO_A3);
                                const s = d ? d['2017'] : undefined;
                                // eslint-disable-next-line max-len
                                const scaled = typeof s === 'string' ? Math.floor(colorScale(parseFloat(s) + Math.random() / 10)) : 0;
                                const fillInt = scaled > 255 ? 255 : scaled;
                                const fillHex = colorHexFromByteValue(fillInt);
                                return (
                                    <Geography
                                        key={ geo.rsmKey }
                                        geography={ geo }
                                        fill={ fillHex }
                                    />
                                );
                            });
                        }
                    }
                </Geographies>
            </ComposableMap>
        </div>
    );
};

export default Map;
