import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import { CARD_STYLE } from './Docs';


const BODY_STYLE = {
    color: '#CEFFFA',
    fontFamily: 'monospace',
    fontWeight: '500',
    fontSize: '1em',
    backgroundColor: '#0E2A33',
} as const;

const TITLE_STYLE = {
    fontSize: '1em',
    color: '#CEFFFA',
} as const;


interface Props {
    title: string;
    body: string | React.ReactElement;
    eventKey: string;
    encrypted: boolean;
    isHackFinished: boolean;
    // eslint-disable-next-line react/require-default-props
    dangerouslySetInnerHtml?: boolean;
}

const FileEntry: React.FC<Props> = (props: Props) => {
    let { title, body, eventKey, encrypted, dangerouslySetInnerHtml, isHackFinished } = props;

    // Remove GPG prefix if hack is complete
    if (isHackFinished && title.endsWith('.gpg')) {
        title = title.replace('.gpg', '');
    }

    // Autolink images
    if (isHackFinished && typeof body === 'string' && body.endsWith('.jpg')) {
        body = (<img src={ body } className="w-100" alt="decrypted hard drive file" />);
    }

    // Autolink Word docs
    if (isHackFinished && typeof body === 'string' && body.endsWith('.docx')) {
        const docUrl = `https://hack.teamzander.com/${body}`;
        const embedUrl = `https://docs.google.com/viewer?url=${encodeURI(docUrl)}&embedded=true`;
        body = (
            <div className="w-100" style={ { height: '500px' } }>
                <iframe src={ embedUrl } className="w-100 h-100" />
            </div>
        );
    }

    // Autofill text files
    if (isHackFinished && typeof body === 'string' && body.endsWith('.txt')) {
        body = (
            <div className="bg-light w-100">
                <object data={ body } type="text/plain" className="w-100" style={ { height: '500px' } }>
                    <a href={ body } target="_blank" rel="noopener noreferrer">Link</a>
                </object>
            </div>
        );
    }

    return (
        <Card style={ CARD_STYLE }>
            <Accordion.Toggle as={ Card.Header } eventKey={ eventKey } style={ TITLE_STYLE }>
                { encrypted && <span role="img" aria-label="locked">🔒&nbsp;</span> }
                { title }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={ eventKey }>
                <Card.Body style={ BODY_STYLE }>
                    {
                        dangerouslySetInnerHtml && typeof body === 'string' ? (
                            // eslint-disable-next-line react/no-danger
                            <div dangerouslySetInnerHTML={ { __html: body } } />
                        ) : body
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default FileEntry;
