import moment from 'moment-timezone';


export const ETA = moment.tz('2020-10-11 09:45:00', 'America/Los_Angeles');

// eslint-disable-next-line max-len
export const S3_ENCRYPTED_LINK = "https://dr-uploads.s3-us-west-2.amazonaws.com/Isaac's+Hard+Drive+%E2%80%94+ENCRYPTED-20200928T234945Z-001.zip";
// TODO: swap with link to decrypted content
// eslint-disable-next-line max-len
export const S3_DECRYPTED_LINK = 'https://dr-uploads.s3-us-west-2.amazonaws.com/Isaac_s+Hard+Drive.rar';

export const ROOT_URL = `${window.location.protocol}//${window.location.hostname}`;
